.angular-editor-toolbar-set {
    .color-picker {
        .box{
            &.hue-alpha  {
                height: 45px;
            }
        }

        .hex-text {
            .box {
                padding: 0 24px 0px 8px !important;
            }
        }

        .button-area {
            padding: 0 6px 6px !important;
            button {
                margin-left: 10px !important;
            }
        }

        .type-policy {
            top: 184px !important;
        }
    }
}

.pallete-column-wrapper {
    display: flex;
    flex-direction: column;
    
    label {
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis; 
        font-size: 12px; 
        color: rgba(0, 0, 0, 0.54);
    }
    input {
        max-width: 180px; 
        padding: 8px 12px; 
        border: 1px solid #ccc !important; 
        color: rgba(0, 0, 0, 0.87); 
        font-size: 1rem;
        outline: none;
    }
}

.pallete-form-style {
    display: flex;
    padding-top: 16px; 
    align-items: center;

    mat-icon {
        padding: 7.5px; 
        margin-top: 18px;
    }
}