/*
 * Override only material controls
 *
 */


@use '@angular/material' as mat;
 $menu-bg-color: mat.get-color-from-palette($airme-app-menu, 400);
 $menu-bg-color-selected: mat.get-color-from-palette($airme-app-menu, 300);
 $menu-text: mat.get-color-from-palette($airme-app-menu, 50);

 :root {
    --mdc-protected-button-label-text-color: #fff;
  }

.mat-sidenav-container {
    height: 100%;

    .content {
        display: flex;
        flex-direction: column;
        flex: 1 2 100%;
        background-color: $content-backgroud;
        height: 100%;
        overflow: hidden;
        background-color: $main-backgroud;
        padding-left: 0px;
        transition: all .3s ease-in-out;
        line-height: 1.2rem;
        /*font-size: 0.8rem;*/
        &.menu-expanded {
            padding-left: $menu-width;
        }
        &.login-content {
            padding-left: 0;
            background-color: $main-backgroud;
        }
    }
    .mat-sidenav-content {
        overflow: initial;
        width: 100%;
        flex-direction: column;
    }
    .mat-sidenav {
        display: flex;
        width: $menu-width;
        overflow: hidden;
        color: $menu-text;
        .outer-container {
            position: relative;
            overflow: hidden;
            height: 100%;
            width: $menu-width;
            color: $menu-text;
            background-color: #434750 /* $menu-bg-color */;
        }
        .inner-container {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            overflow-x: hidden;
            overflow-y: auto;
            width: $menu-width + 20px;
        }
        .inner-container::-webkit-scrollbar {
            display: none;
        }
        .mat-mdc-button {
            width: 200px;
            text-align: left;
            padding: 0 13px;
            flex: 0 0 200px;
            flex-direction: row;
            color: white;
            /* font-weight: 400; */
            
            .mdc-button__label {
                display: inline-flex;
                flex: 1 1 100%;
                font-weight: normal;
                .menu-item-row {
                    display: flex;
                    flex: 1 1 100%;

                    .mat-icon {
                        flex: 0 0 20px;
                    }

                    .menu-text {
                        display: flex;
                        flex: 1 0 calc(100% - 34px);
                        
                    }

                    .menu-expand {
                        position: absolute;
                        right: -11px;
                        transform: rotate(270deg);
                        transition: all .3s ease-in-out;
                        &.expanded {
                            transform: rotate(360deg);
                        }
                    }
                }
            }
  
            .mdc-button__ripple {
                width: 100%;
                display: block;
                // position: relative;

            }
        }
        .subMenu {
            width: $menu-width - 10px;
            padding-left: 10px;
            overflow: hidden;
            height: 0;
            opacity: 0;
            &.expanded {
                height: auto;
                opacity: 1;
            }
            .mat-icon {
                font-size: 1.6em;
            }
            .mat-mdc-button {
                width: calc(100% - 13px);
                display: flex;
                flex: 1 1 100%;
                height: 30px;
                line-height: 30px;
                padding-right: 0;
                /*font-size: 0.8em;*/
            }
        }
    }
}

.mat-mdc-dialog-content {
    min-width: 512px;
    .mat-mdc-checkbox {
        padding-top: 8px;
        padding-left: 10px;
        margin-bottom: 8px;
    }
    .dialog-placeholder {
        height: 51px;
    }
    .mat-mdc-tab-body {
        padding-top: 15px;
    }

    .mat-mdc-tab-label-container {
        flex-grow: 0;
    }
}

.mat-icon {
    &.selected {
        color: $secondary-color;
    }
    &.deselected {
        color: $secondary-text-color;
    }
}

.mat-mdc-select-panel {
    .mat-mdc-option {
        line-height: 1.4rem !important;
        //height: 2rem !important;
        font-size: 1rem !important;
        min-height: 28px;
    }
}

input.mat-mdc-input-element,
textarea.mat-mdc-input-element {
    background-color: #f5f7f7;
    border: 1px solid #ccc !important;
    min-height: 30.8px;
    padding-left: 12px;
    width: calc(100% - 15px);
    font-size: $base-font-size;
    &[readonly] {
        background-color: $main-backgroud;
        border: 1px solid $main-backgroud;
        width: calc(100% - 15px);
    }
}



.mat-mdc-form-field {
    padding-top: 0px;
    // height: 70px; // destroys report-grid.component.html

    label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.54);
    }

    .mat-mdc-text-field-wrapper {
        height: auto;
        padding: 0;
        //padding-top: 16px;
    }

    .mat-mdc-form-field-subscript-wrapper {
        height: 0px;
    }

    &.mat-form-field-appearance-legacy {
        .mat-mdc-form-field-infix {
            padding: 0;
        }
        .mdc-line-ripple {
            background-color: transparent;
        }
    }

    &[fxFlex] {
        .mat-mdc-form-field-flex {
            width: 100%;
            .mat-mdc-form-field-infix {
                width: 100%;
                padding-top: 4px !important;
                padding-bottom: 0px !important;
                border-top: 0;
                flex-direction: column !important;
                display: flex;
                flex: 1 0 100%;

                .mat-mdc-floating-label  {
                    top: 5px;
                    font-size: 14px;
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }

    .mdc-line-ripple {
        background-color: transparent;
    }

    .mat-mdc-form-field-infix {
        padding-bottom: 0 !important;
        padding-top: 3 !important;
        // border-top: 19px solid transparent; // damages gallery search row
    }

    .mat-mdc-form-field-text-suffix {
        position: absolute;
        //top: 18px; causes datepicker toggle to be higher than the input element
        right: 0;
        bottom: 0;
        overflow: hidden;
        line-height: 30px;
    }
    .mat-mdc-floating-label {
        top: -1.1em;
    }

    .mat-mdc-form-field-icon-suffix {
        align-self: flex-end;
        display: flex;
        height: 40px;
        position: relative; 
        right: 60px;
        top: -7px;
    }

    .mat-mdc-icon-button {

        .mat-mdc-focus-indicator, 
        .mat-mdc-button-persistent-ripple,
        .mat-mdc-button-base,
        .mat-mdc-button-touch-target  {
            width: 32px;
            height: 32px;
            padding: 4px;
            top: 6px;
            left: 4px;
        }
    }

    .mat-mdc-form-field-flex {
        position: relative;
    }

    textarea {
        &.mat-mdc-input-element {
            padding-top: 5px;
            width: 100%;
        }
    }

    .mdc-text-field--filled .mdc-floating-label--float-above {
        transform: translateY(-150%) scale(1);
        font-size: 11.5px !important;
    }
}

.mat-mdc-checkbox {
    height: 25px;
    

    .mdc-checkbox__checkmark {
        --mdc-checkbox-selected-checkmark-color: #FFF;
    }

    .mat-mdc-checkbox-touch-target {
        height: 25px;
    }

    label {
        line-height: 25px;
    }
}

.mat-mdc-form-field-hint {
    font-size: 0.75rem;
    padding-top: 0;
    margin-top: -5px;
    margin-left: -15px;
}

hc-grid-template {
    .pager {

        .mat-mdc-form-field {
            label {
                top: -1.0em;
                font-weight: normal;    
            }
        }

        .mat-mdc-form-field-infix {
            width: 80px;
            padding: 0;
            margin: 0;
            border-top: 0;
        }

        .mat-mdc-select-value {
            // line-height: 1.3rem;
            padding-left: 12px;
            font-size: 0.9rem;
        }

        .mat-mdc-select-trigger {
            background-color: $main-backgroud;
            height: 1.5rem;
        }

        //.mat-select-content,
        .mat-mdc-select-panel .mat-mdc-optgroup-label {
            .mat-mdc-option {
                line-height: 1.4rem !important;
                height: 2rem !important;
                min-height: 28px;
            }
        }
    }
}

hc-report-grid {
    .mat-mdc-form-field {
        .mat-mdc-text-field-wrapper {
            //height: auto;
            padding: 0;
            //padding-top: 16px;  // destroys report-grid.component.html
        }
    }
}

.mat-mdc-table {
    width: 100%;
    &.table-with-summary .mat-mdc-row:last-child {
        font-weight: bold;
    }
    .mat-mdc-header-row {
        min-height: 30px;
        .mat-mdc-header-cell {
            font-weight: bold;
            font-size: 1em;
        }
        border-top: 1px solid $secondary-text-color;
        border-bottom: 1px solid $secondary-text-color;
    }
    .mat-mdc-cell,
    .mat-mdc-header-cell {
        padding: 0 5px;
        &.mat-column-index {
            flex: 0 0 30px;
        }
        &.number,
        &.money {
            text-align: right;
            justify-content: flex-end;
        }
    }
    .mat-mdc-row {
        //min-height: 30px;
        max-height: 150px;
        border-bottom: none;
        .mat-mdc-cell {
            cursor: pointer;
            height: 100%;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            overflow: hidden;
            .value-row {
                display: flex;
                .no-image {
                    width: 40px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
        &:nth-child(odd) {
            //background-color: $table-bg-accent; // $table-bg-accent;
            background-color: #f5f7f7;
        }
        &:hover {
            background-color: $focus-color;
        }
    }
    .wrap {
        text-overflow: ellipsis;
        height: 20px;
        white-space: nowrap;
        overflow: hidden;
        margin: auto 0;

        // &.text {

        // }
        &.html {
            max-height: 150px;
            display: block;
            overflow-y: auto;
            height: auto;

            > p {
                margin: 0;
            }
        }
    }
    .mat-mdc-row {
        position: relative;
        img {
            position: relative;
            height: 25px;
            width: 40px;
        }
        button {
            position: relative;
            height: 25px;
            line-height: 26px;
            min-width: auto;
        }
    }

    .mat-header-align-right, .mat-cell-align-right {
        display:flex;
        justify-content:flex-end;
    }

    &.readonly {
        .mat-mdc-row {
            .mat-mdc-cell {
                cursor: default;
                .value-row {
                    cursor: pointer;
                    max-height: 150px;
                    &.html-row {
                        overflow: auto;
                    }
                }
                div {
                    width: 100%;
                    min-width: 16px;
                }
            }
        }
    }
}

.mat-mdc-select {

    width: calc(100% - 1px) !important;

    .mat-mdc-select-trigger {
        background-color: #f5f7f7;
        border: 1px solid #ccc;
        height: 2.3rem;

        .mat-mdc-select-value {
            vertical-align: middle;
            font-size: 1.0rem;
            padding-left: 10px;
        }
    }
}

.mat-grid-tile {
    .mat-figure {
        justify-content: left !important;
    }
}

/* buttons */
.mat-mdc-button, .mat-mdc-raised-button, .mat-mdc-icon-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button, .mat-mdc-fab, .mat-mdc-mini-fab {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.mat-mdc-button,
.mat-mdc-raised-button {
    border-radius: 0 !important;
    -webkit-box-shadow: 1px 1px 2px -1px rgba(0,0,0,0.5) !important;
    -moz-box-shadow: 1px 1px 2px -1px rgba(0,0,0,0.5) !important;
    box-shadow: 1px 1px 2px -1px rgba(0,0,0,0.5) !important;
    background-color: #fff;
    max-height: 36px;
    margin: 0 3px;
    //font-weight: 500;
    font-size: 15px;
    &:hover,
    &:focus,
    &:active {
        background-color: darken( #fff, 10%) !important;
    }
}
.mat-mdc-button.mat-primary,
.mat-mdc-raised-button.mat-primary {
    color: $menu-text-color;
    background-color: $accent-bg-color;
    &:hover,
    &:focus,
    &:active {
        background-color: darken( $accent-bg-color, 10%) !important;
    }
}
.mat-mdc-button.mat-warn,
.mat-mdc-raised-button.mat-warn {
    color: #fff;
    background-color: #CC6666;
    &:hover,
    &:focus,
    &:active {
        background-color: darken( #CC6666, 10%) !important;
    }
}
.mat-sidenav .mat-mdc-button,
.right-menu .mat-mdc-raised-button {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-color: transparent;
    &:hover,
    &:focus,
    &:active {
        background-color: transparent !important;
        color: $accent-bg-color;
    }
}

.mat-mdc-button,
.mat-mdc-raised-button {
    &.button-in-row {
        height: 30px;
        margin-top: 17px;

        .mdc-button__ripple {
            width: 100%;
            .mat-icon,
            .mat-mdc-progress-spinner {
                position: absolute;
                left: 6px;
                top: 6px;
            }
        }
    }
}

.mat-datepicker-toggle {
    &>.mat-mdc-icon-button {
        height: 100% !important;

        &>.mat-mdc-button-ripple, &>.mat-mdc-focus-indicator  {
            top: 8px;
            left: 8px;
            width: 32px;
            height: 32px;
        }
    }  
}

.mat-icon {
    &.rotate-90 {
        transform: rotate(90deg);
    }
    &.rotate-180 {
        transform: rotate(180deg);
    }
    &.rotate-270 {
        transform: rotate(270deg);
    }
}

.mat-mdc-radio-group {
    padding-bottom: 20px;
    &.centerize {
        padding-top: 20px;
    }
}

.mdc-button__ripple>.mat-mdc-progress-spinner {
    left: 6px;
    top: 4px;
    position: absolute;
}

.login-button .mdc-button__ripple>.mat-mdc-progress-spinner {
    right: 10px;
    left: auto;
}

.mat-mdc-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .mat-mdc-menu-item {
        line-height: 35px;
        height: 35px;
    }
}

.tab-field {
    flex: 1; 
    overflow: auto;
}

.mat-mdc-tab-group {
    height: 100%;
}

.mat-mdc-tab-body {
    height: calc(100% - 15px); /* full height minus padding-top */
    padding-top: 15px;
}

.mat-mdc-tab-body-wrapper {
    /* padding-bottom: 60px; */
    height: 100%;
}

.mat-mdc-tab-body-content {
    height: 100%;
}

.mat-expansion-panel {
    .mat-expansion-panel-header {
        display: flex;
        flex: 1 0 48px;
    }
}

.ngx-pagination {
    margin-left: 0;
    margin-bottom: 1rem;

    &::before, &::after {
        content: ' ';
        display: table; }
    &::after {
        clear: both; }

    li {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        margin-right: 0.0625rem;
        border-radius: 0;
        display: inline-block;
    }

    a, button {
        color: $table-header-font-color;
        display: block;
        padding: 0.1875rem 0.625rem;
        border-radius: 0;
        cursor: pointer;
        &:hover {
            background: $content-backgroud;;
        }
    }

    .current {
        padding: 0.1875rem 0.625rem;
        color: $content-backgroud;
        cursor: default;
    }

    .disabled {
        color: #cacaca;
        cursor: default;
        &:hover {
            background: transparent;
        }
    }

    .ellipsis::after {
        content: '…';
        padding: 0.1875rem 0.625rem;
        color: $table-header-font-color;
    }

    .pagination-previous a::before {
        content: '«';
        display: inline-block;
        margin-right: 0.4rem;
        font-size: 1rem;
    }

    .pagination-next a::after {
        content: '»';
        display: inline-block;
        margin-left: 0.4rem;
        font-size: 1rem;
    }

    .show-for-sr {
        position: absolute !important;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
    }
}

/* uploader */
.file-upload #holder {
    text-align: right !important; /* upload area text position */
    line-height: 22px;
    padding-right: 10px;
}
.file-upload.container button { 
    top: 8px !important; /* upload area button position */
    margin: 0 !important;
    left: 6px;
}

/* nav icons */
.mat-icon {
    height: 18px !important; /* smaller nav icons */
    width: 18px !important;
    margin-right: 3px;
}
.toggle-menu-btn .mat-icon,
.info-button .mat-icon {
    height: 24px !important; /* some bigger icons */
    width: 24px !important;
    font-size: 24px !important;
    margin-right: 0 !important;
}
.material-icons {
    font-size: 18px !important;
}

/* history row */
.content .navigation {
    height: auto; /* size and ux history nav */
    margin: 0;
    padding: 13px 10px 10px;
}

/* action row */
.action-row-inner {
    height: auto; /* size and ux action row */
}

.inlineList li a:hover {
    color: $accent-bg-color;; /* ux hover color on history nav */
}

/* content holder */
main .outlet-holder {
    margin: 1px 0 0 0; /* margins in content area */
    height: calc(100% - 50px) !important;
    box-sizing: border-box;
    overflow: initial !important;
}

/* tables */
.table > thead > tr > th {
    border-width: 1px; /* thead divider width */
}
/* dark thead experiment 
.table > thead.column-head > tr > th {
    background-color: #333; 
    color: #fff;
}
*/

/* tabs */
.content .mat-mdc-tab-nav-bar, 
.content .mat-mdc-tab-header {
    border-bottom: 1px solid #ccc;
}

.content .mdc-tab__text-label {
    height: 36px;
    padding: 0 20px;
    min-width: 140px;
    border-right: 1px solid #bbb;
}

.content .mdc-tab__text-label:focus {
    opacity: .6 !important;
}

/* forms */
.content .mat-mdc-floating-label {
    font-size: 20px;
    font-weight: 400;
}

.content {
    .mat-mdc-tab {
        padding: 0;

        &.mdc-tab {
            height: 35px;
            flex-grow: 0 !important;
        }

        .mdc-tab__content {
            height: 35px;

        }

        .mdc-tab-indicator {
            height: 35px;
        }
    }
}

.mat-mdc-focus-indicator {
    background: transparent !important;
}

/* content */
.mat-sidenav-container .content {
    background-color: #ddd; /* main content background */
}
.content .mat-mdc-dialog-title {
    font-weight: 700; /* main titles */
    margin-top: 0px;
    margin-bottom: 15px;
}
.content .mat-mdc-card {
    box-shadow: none !important; /* mat-cards no shadow */
    -webkit-box-shadow: none !important;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin-bottom: -1px;
    border-radius: 0;
    padding: 10px;

    .mat-mdc-card-header {
        padding: 16px 16px 16px 0;
    }

    .mat-mdc-card-content {
        padding: 0;
        margin-top: -15px;

        .mdc-checkbox {
            margin-left: -10px;
        }
    }

    .mat-mdc-card-content:last-child {
        padding-bottom: 16px;
    }
}
.content .mat-mdc-card-header .mat-mdc-card-title {
    font-size: 16px; /* mat-cards header size */
}
.content .mat-mdc-table .mat-mdc-header-row {
    border-top: 1px solid #ccc; /* mat-table header borders */
    border-bottom: 1px solid #ccc;
}
.content .container[_ngcontent-c38],
.content .container[_ngcontent-c43] {
    box-shadow: none; /* media preview */
    border: 1px solid #ccc;
}
.ql-toolbar.ql-snow {
    background-color: #f5f7f7; /* wysiwyg editor toolbar background */
}
.content .node-content-wrapper {
    padding: 5px 10px; /* tree padding */
}
.material-icons.checkbox_true {
    color: $accent-bg-color; /* checkbox true icon color */
}
.toggle-children {
    top: 5px; /* tree node icon position */
}
.node-content-wrapper .mat-mdc-checkbox {
    margin-right: 5px; /* tree node checkbox margin */
}

.mat-form-field-appearance-legacy .mat-mdc-text-field-wrapper {
    padding-bottom: .75em !important; /* form fields bottom margin */
}
.mat-toolbar {
    background: #fff !important; /* toolbar after title background */
    margin-bottom: 15px !important;
}

.mat-form-field-appearance-legacy .mdc-line-ripple {
    bottom: .8em !important; /* active selectbox underline position */
}
input.mat-mdc-input-element {
    margin-top: 0 !important;
}
.mat-mdc-input-element {
    caret-color: initial !important;
}

/* header select */

header .mat-mdc-form-field-infix {
    border-top: 0.4em solid transparent !important;
}

header .mat-mdc-floating-label {
    display: none;
}

.input-field .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 0px !important;
}
